import React, { useState, useEffect, useRef } from 'react';
import { 
  Container, 
  Typography, 
  Box,
  Paper,
  useTheme,
  useMediaQuery,
  Button,
  Grid,
  TextField,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  MobileStepper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Alert,
  Snackbar,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  DialogContentText
} from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SignatureInput from '../components/SignatureInput';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import { useNavigate } from 'react-router-dom';
import { signup, uploadDrivingLicense, getPlayerProfile, uploadSignedDocument, login, logout, updatePlayerWaiverInfo, updatePlayerIdInfo } from '../services/apiService';
import UploadIcon from '@mui/icons-material/Upload';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Webcam from 'react-webcam';
import { generateWaiverPDF } from '../utils/waiverPdfGenerator';
import { waiverText } from '../utils/waiverText';
import CloseIcon from '@mui/icons-material/Close';
import AuthModal from '../components/AuthModal';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const WaiverFormPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    dateOfBirth: '',
    idType: 'drivers_license',
    idNumber: '',
    idStateCountry: '',
    idExpirationDate: '',
  });

  const [signature, setSignature] = useState(null);

  const [completedSteps, setCompletedSteps] = useState(new Set());

  const navigate = useNavigate();

  const [error, setError] = useState(null);

  const [licenseFile, setLicenseFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const [isExistingUser, setIsExistingUser] = useState(false);

  const [idFile, setIdFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const [useCamera, setUseCamera] = useState(false);
  const webcamRef = React.useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [signatureMethod, setSignatureMethod] = useState(null);

  const [showCancelDialog, setShowCancelDialog] = useState(false);

  const [showAuthModal, setShowAuthModal] = useState(() => {
    const token = localStorage.getItem('token');
    return !token;
  });

  const [lastWaiverDate, setLastWaiverDate] = useState(null);

  const [playerId] = useState(() => localStorage.getItem('playerId'));

  useEffect(() => {
    const playerId = localStorage.getItem('playerId');
    if (playerId) {
        fetchAndPopulatePlayerData(playerId);
    }
  }, [playerId]);

  const fetchAndPopulatePlayerData = async (playerId) => {
    if (!playerId) {
        console.log('No player ID provided');
        return;
    }

    setIsSubmitting(true);
    try {
        const profile = await getPlayerProfile(playerId);
        console.log('Raw profile data:', profile);

        if (!profile) {
            console.error('No profile data received');
            setSnackbar({
                open: true,
                message: 'Error loading your information',
                severity: 'error'
            });
            return;
        }

        setFormData(prev => {
            const newData = {
                ...prev,
                email: profile.Email,
                firstName: profile.FirstName,
                lastName: profile.LastName,
                phoneNumber: profile.PhoneNumber || '',
                dateOfBirth: profile.DOB || '',
                legalName: profile.LegalName || '',
                homeAddress: profile.HomeAddress || '',
                idType: profile.IDType || 'drivers_license',
                idNumber: profile.IDNumber || '',
                idStateCountry: profile.IDCountry || '',
                idExpirationDate: profile.IDExpirationDate || '',
                SignedDocumentURL: profile.SignedDocumentURL
            };
            console.log('Updated form data:', newData);
            return newData;
        });

        if (profile.DrivingLicenseURL) {
            setImagePreviewUrl(profile.DrivingLicenseURL);
        }

        if (profile.SignedDocumentURL) {
            const waiverDate = new Date(profile.updatedAt);
            setLastWaiverDate(waiverDate);
        }

        console.log('Pre-filled data from profile:', profile);
        return profile;
    } catch (error) {
        console.error('Error fetching player profile:', error);
        setSnackbar({
            open: true,
            message: 'Error loading your information. Some fields may need to be re-entered.',
            severity: 'warning'
        });
    } finally {
        setIsSubmitting(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };
  
  const handleNext = async () => {
    if (activeStep === 3) {
        if (!formData.firstName || !formData.lastName || !formData.legalName || 
            !formData.homeAddress || !formData.phoneNumber || !formData.dateOfBirth) {
            setSnackbar({
                open: true,
                message: 'Please fill in all required fields',
                severity: 'error'
            });
            return;
        }

        if (!validatePhoneNumber(formData.phoneNumber)) {
            setSnackbar({
                open: true,
                message: 'Please enter a valid 10-digit phone number',
                severity: 'error'
            });
            return;
        }

        setIsSubmitting(true);
        try {
            const playerId = localStorage.getItem('playerId');
            const updateResult = await updatePlayerWaiverInfo(playerId, {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                phoneNumber: formData.phoneNumber,
                dateOfBirth: formData.dateOfBirth,
                legalName: formData.legalName,
                homeAddress: formData.homeAddress
            });

            setSnackbar({
                open: true,
                message: 'Information saved successfully',
                severity: 'success'
            });
            
            setActiveStep((prevStep) => prevStep + 1);
        } catch (error) {
            console.error('Update error:', error);
            setActiveStep((prevStep) => prevStep + 1);
        } finally {
            setIsSubmitting(false);
        }
    } else if (activeStep === 4) {
        if (!formData.idType || !formData.idNumber || 
            !formData.idStateCountry || !formData.idExpirationDate || 
            (!idFile && !imagePreviewUrl)) {
            setSnackbar({
                open: true,
                message: 'Please fill in all ID information and upload your ID',
                severity: 'error'
            });
            return;
        }

        setIsSubmitting(true);
        try {
            const playerId = localStorage.getItem('playerId');
            
            // First update ID information
            const updateResult = await updatePlayerIdInfo(playerId, {
                idType: formData.idType,
                idNumber: formData.idNumber,
                idStateCountry: formData.idStateCountry,
                idExpirationDate: formData.idExpirationDate
            });

            // Only upload new ID if a new file was selected
            if (idFile) {
                await uploadDrivingLicense(playerId, idFile);
            }

            setSnackbar({
                open: true,
                message: 'ID information saved successfully',
                severity: 'success'
            });
            
            setActiveStep((prevStep) => prevStep + 1);
        } catch (error) {
            console.error('Error updating ID information:', error);
            setSnackbar({
                open: true,
                message: 'Error saving ID information. Please try again.',
                severity: 'error'
            });
        } finally {
            setIsSubmitting(false);
        }
    } else if (activeStep === 5) {
        if (!signature) {
            setSnackbar({
                open: true,
                message: 'Please provide your signature',
                severity: 'error'
            });
            return;
        }
        setActiveStep((prevStep) => prevStep + 1);
    } else {
        setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async () => {
    if (!signature) {
        setSnackbar({
            open: true,
            message: 'Please provide your signature',
            severity: 'error'
        });
        return;
    }

    setIsSubmitting(true);
    try {
        // Generate PDF using our utility
        const pdfResult = await generateWaiverPDF(formData, signature);
        
        // Create a File object from the PDF bytes
        const pdfBlob = new Blob([pdfResult.bytes], { type: 'application/pdf' });
        const pdfFile = new File([pdfBlob], pdfResult.fileName, { type: 'application/pdf' });

        // Upload signed document
        const playerId = localStorage.getItem('playerId');
        await uploadSignedDocument(playerId, pdfFile);

        // Navigate to success page
        navigate('/thank-you');
    } catch (error) {
        console.error('Submission error:', error);
        setSnackbar({
            open: true,
            message: 'Failed to submit waiver. Please try again.',
            severity: 'error'
        });
    } finally {
        setIsSubmitting(false);
    }
  };

  const handleStepClick = (step) => {
    setActiveStep(step);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLicenseFile(file);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIdFile(file);
      const url = URL.createObjectURL(file);
      setImagePreviewUrl(url);
    }
  };

  const toggleCamera = () => {
    setUseCamera((prev) => !prev);
    if (!useCamera) {
      setImagePreviewUrl(null);
    }
  };

  const captureImage = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        setImagePreviewUrl(imageSrc);
        setUseCamera(false);
        fetch(imageSrc)
          .then(res => res.blob())
          .then(blob => {
            const fileName = `captured-${Date.now()}.jpg`;
            const capturedFile = new File([blob], fileName, { type: 'image/jpeg' });
            setIdFile(capturedFile);
          });
      }
    }
  };

  const handleCancel = () => {
    setShowCancelDialog(true);
  };

  const handleConfirmCancel = () => {
    setIsSubmitting(true);
    logout();
  };

  const handleAuthSuccess = async () => {
    const token = localStorage.getItem('token');
    const playerId = localStorage.getItem('playerId');
    
    if (token && playerId) {
        const profile = await fetchAndPopulatePlayerData(playerId);
        if (profile) {
            setShowAuthModal(false);
            setIsExistingUser(true);
        }
    }
  };

  const steps = [
    {
      label: 'Event Agreement & Code of Conduct',
      content: (
        <>
          <Typography variant="body1" paragraph>
            {waiverText.introduction}
          </Typography>
          <Typography variant="body1" paragraph>
            {waiverText.codeOfConduct}
          </Typography>
        </>
      )
    },
    {
      label: 'Personal Conduct & Safety',
      content: (
        <>
          <Typography variant="body1" paragraph>
            {waiverText.personalConduct}
          </Typography>
          <Typography variant="body1" paragraph>
            {waiverText.medicalSafety}
          </Typography>
        </>
      )
    },
    {
      label: 'League Policies',
      content: (
        <>
          <Typography variant="body1" paragraph>
            {waiverText.antiGambling}
          </Typography>
          <Typography variant="body1" paragraph>
            {waiverText.antiDoping}
          </Typography>
        </>
      )
    },
    {
      label: 'Personal Information',
      content: (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              variant="outlined"
              defaultValue={formData.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              variant="outlined"
              defaultValue={formData.lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              label="Legal Name (as it appears on ID)"
              name="legalName"
              value={formData.legalName}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              label="Home Address"
              name="homeAddress"
              value={formData.homeAddress}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="Phone Number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="Date of Birth"
              name="dateOfBirth"
              type="date"
              value={formData.dateOfBirth}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            />
          </Grid>
        </Grid>
      )
    },
    {
      label: 'Government ID',
      content: (
        <>
          <Typography variant="body1" paragraph>
            Please provide your government-issued identification
          </Typography>
          
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel>ID Type</FormLabel>
                <RadioGroup
                  name="idType"
                  value={formData.idType}
                  onChange={handleInputChange}
                  row
                >
                  <FormControlLabel 
                    value="drivers_license" 
                    control={<Radio />} 
                    label="Driver's License" 
                  />
                  <FormControlLabel 
                    value="id_card" 
                    control={<Radio />} 
                    label="ID Card" 
                  />
                  <FormControlLabel 
                    value="passport" 
                    control={<Radio />} 
                    label="Passport" 
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Identification Number"
                name="idNumber"
                value={formData.idNumber}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="State/Country of Issue"
                name="idStateCountry"
                value={formData.idStateCountry}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                label="Expiration Date"
                name="idExpirationDate"
                type="date"
                value={formData.idExpirationDate}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              {useCamera ? (
                <>
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width="100%"
                    videoConstraints={{ 
                      facingMode: isMobile ? { exact: "environment" } : "user",
                      width: 1280,
                      height: 720
                    }}
                    style={{
                      width: '100%',
                      borderRadius: '4px'
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={captureImage}
                    sx={{ mt: 2 }}
                    fullWidth
                  >
                    Capture Photo
                  </Button>
                </>
              ) : (
                <>
                  <input
                    accept="image/*"
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    id="id-upload"
                  />
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <label htmlFor="id-upload">
                      <Button
                        variant="outlined"
                        component="span"
                        startIcon={<UploadIcon />}
                        fullWidth
                      >
                        {idFile ? 'Change ID Image' : 'Upload ID Image'}
                      </Button>
                    </label>
                    <Button
                      variant="outlined"
                      onClick={toggleCamera}
                      startIcon={<PhotoCameraIcon />}
                    >
                      Use Camera
                    </Button>
                  </Box>
                </>
              )}
              
              {idFile && !useCamera && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Selected file: {idFile.name}
                </Typography>
              )}
            </Grid>

            {imagePreviewUrl && !useCamera && (
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>Preview:</Typography>
                <img 
                  src={imagePreviewUrl} 
                  alt="ID Preview" 
                  style={{ maxWidth: '100%', marginTop: 2 }} 
                />
              </Grid>
            )}
          </Grid>
        </>
      )
    },
    {
      label: 'Sign Agreement',
      content: (
        <>
          <Typography variant="body1" paragraph>
            By signing below, I acknowledge that I have read and understand all terms and conditions 
            outlined in this agreement. I agree to comply with all policies and requirements set forth 
            by Pro League Network Inc.
          </Typography>
          
          <Box sx={{ mb: 3 }}>
            <SignatureInput 
              onSignatureChange={(signatureData) => {
                setSignature(signatureData);
                setSignatureMethod(signatureData.type);
                console.log('Signature data received:', signatureData);
              }}
              onMethodChange={(method) => {
                // Clear previous signature when switching methods
                setSignature(null);
                setSignatureMethod(method);
              }}
            />
          </Box>

          {signature && signatureMethod === signature.type && (
            <Box sx={{ mt: 2, mb: 2 }}>
              <Typography variant="subtitle2" gutterBottom>
                Signature Preview:
              </Typography>
              {signature.type === 'typed' ? (
                <Typography variant="body1" sx={{ fontFamily: 'cursive' }}>
                  {signature.data}
                </Typography>
              ) : (
                <img 
                  src={signature.data} 
                  alt="Signature" 
                  style={{ 
                    maxWidth: '300px',
                    maxHeight: '100px',
                    border: '1px solid #eee',
                    borderRadius: '4px',
                    padding: '10px'
                  }} 
                />
              )}
            </Box>
          )}

          <Typography variant="body2" color="text.secondary">
            Date: {new Date().toLocaleDateString()}
          </Typography>
        </>
      )
    }
  ];

  const LoadingOverlay = () => (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        zIndex: 9999,
      }}
    >
      <CircularProgress />
    </Box>
  );

  return (
    <>
      <AuthModal 
        open={showAuthModal}
        onClose={() => {}}
        onSuccess={handleAuthSuccess}
      />
      
      <Container maxWidth="md">
        <Box sx={{ 
          position: 'fixed', 
          top: 16, 
          right: 16, 
          zIndex: 1000 
        }}>
          <IconButton 
            onClick={handleCancel}
            size="small"
            sx={{ 
              color: 'error.main',
              bgcolor: 'background.paper',
              boxShadow: 1,
              '&:hover': { bgcolor: 'error.light', color: 'white' }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Dialog
          open={showCancelDialog}
          onClose={() => setShowCancelDialog(false)}
        >
          <DialogTitle>Cancel Application?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to cancel? All progress will be lost.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowCancelDialog(false)}>No, Continue</Button>
            <Button onClick={handleConfirmCancel} color="error" autoFocus>
              Yes, Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Box sx={{ my: 4 }}>
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <Typography 
              variant="h4" 
              component="h1" 
              gutterBottom
            >
              Str33t Event Agreement
            </Typography>
            
            <Typography 
              variant="subtitle1" 
              color="text.secondary"
              sx={{ mb: 2 }}
            >
              Please complete this waiver.
            </Typography>
            
            {lastWaiverDate && (
              <Box sx={{ mb: 2 }}>
                <Typography 
                  variant="subtitle1" 
                  color="primary"
                  sx={{ 
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1
                  }}
                  onClick={() => window.open(formData.SignedDocumentURL, '_blank')}
                >
                  <PictureAsPdfIcon />
                  Last waiver submitted: {lastWaiverDate.toLocaleDateString()}
                </Typography>
              </Box>
            )}
            
            {formData.firstName && (
              <Typography variant="body1">
                Current Info: {formData.firstName} {formData.lastName} | {formData.email}
              </Typography>
            )}
          </Box>

          <Paper elevation={3} sx={{ p: isMobile ? 2 : 4, mb: 4 }}>
            {isMobile ? (
              <>
                <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                  {steps[activeStep].label}
                </Typography>
                <Box 
                  sx={{ 
                    mb: 4,
                    maxHeight: '60vh',
                    overflowY: 'auto',
                    pb: 2,
                    WebkitOverflowScrolling: 'touch',
                    overscrollBehavior: 'contain',
                    touchAction: 'pan-y',
                    '-webkit-overflow-scrolling': 'touch',
                    position: 'relative',
                    zIndex: 1,
                    '&:focus': {
                      outline: 'none'
                    }
                  }}
                  tabIndex={0}
                >
                  {steps[activeStep].content}
                </Box>
                <Box sx={{ mb: 3 }}>
                  <MobileStepper
                    variant="dots"
                    steps={steps.length}
                    position="static"
                    activeStep={activeStep}
                    sx={{ 
                      flexGrow: 1, 
                      mb: 2,
                      '.MuiMobileStepper-dots': {
                        gap: '8px'
                      },
                      '.MuiMobileStepper-dot': {
                        width: 10,
                        height: 10
                      }
                    }}
                    nextButton={
                      <IconButton onClick={handleNext} disabled={activeStep === steps.length - 1}>
                        <KeyboardArrowRight />
                      </IconButton>
                    }
                    backButton={
                      <IconButton onClick={handleBack} disabled={activeStep === 0}>
                        <KeyboardArrowLeft />
                      </IconButton>
                    }
                  />
                </Box>
              </>
            ) : (
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step 
                    key={step.label} 
                    completed={completedSteps.has(index)}
                  >
                    <StepLabel 
                      StepIconComponent={(props) => {
                        const { icon } = props;
                        return icon;
                      }}
                    >
                      {step.label}
                    </StepLabel>
                    <StepContent>
                      {step.content}
                      <Box sx={{ mb: 2 }}>
                        <div>
                          <Button
                            variant="contained"
                            onClick={handleNext}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                          </Button>
                          <Button
                            disabled={index === 0}
                            onClick={handleBack}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Back
                          </Button>
                        </div>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            )}

            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
              <Button 
                variant="contained"
                onClick={handleSubmit}
                sx={{
                  backgroundColor: '#1c1c1c',
                  color: '#fff',
                  borderRadius: '50px',
                  padding: '10px 40px',
                  fontWeight: 'bold',
                  display: {
                    xs: activeStep === steps.length - 1 ? 'block' : 'none',
                    sm: 'block'
                  }
                }}
              >
                Submit Agreement
              </Button>
            </Box>

            {isSubmitting && <LoadingOverlay />}

          </Paper>
        </Box>

        <Snackbar 
          open={snackbar.open} 
          autoHideDuration={6000} 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        >
          <Alert severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

const ScrollHelper = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showScrollHint, setShowScrollHint] = useState(true);
  const containerRef = useRef(null);

  useEffect(() => {
    if (!isMobile) return; // Don't run on desktop

    const container = containerRef.current;
    if (!container) return;

    const handleScroll = () => {
      const isAtBottom = 
        container.scrollHeight - container.scrollTop <= container.clientHeight + 50;
      setShowScrollHint(!isAtBottom);
    };

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [isMobile]);

  return (
    <Box position="relative" ref={containerRef}>
      {children}
      {isMobile && showScrollHint && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '50px',
            background: 'linear-gradient(transparent, rgba(255,255,255,0.9))',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pointerEvents: 'none'
          }}
        >
          <Typography variant="caption" color="text.secondary">
            Scroll to continue
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const validatePhoneNumber = (phone) => {
  // Allow formats: 1234567890, 123-456-7890, (123) 456-7890
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return phoneRegex.test(phone);
};

export default WaiverFormPage;

