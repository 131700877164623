import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { waiverText } from './waiverText';
import { getPlayerProfile } from '../services/apiService';

const sanitizeText = (text) => {
  return text
    .replace(/\t/g, '    ') // Replace tabs with spaces
    .replace(/\r\n/g, '\n') // Normalize line endings
    .replace(/\r/g, '\n')
    .replace(/\n\s*\n/g, '\n\n') // Normalize multiple line breaks
    .trim();
};

const replaceTemplateVariables = (text, formData) => {
  return text
    .replace(/{{tournamentName}}/g, formData.tournamentName || 'PLN Event')
    .replace(/{{venue}}/g, formData.venue || '1157 Lemoyne St')
    .replace(/{{startDate}}/g, formData.startDate || 'TBD')
    .replace(/{{prizes}}/g, formData.prizes || '0');
};

export const generateWaiverPDF = async (formData, signature) => {
  console.log('Starting PDF generation with form data:', formData);
  
  // Get verified name from DB
  const playerId = localStorage.getItem('playerId');
  let verifiedName;
  
  try {
    const playerProfile = await getPlayerProfile(playerId);
    console.log('Player profile from DB:', playerProfile);
    verifiedName = `${playerProfile.FirstName} ${playerProfile.LastName}`;
    console.log('Using verified name from DB:', verifiedName);
  } catch (error) {
    console.error('Error fetching player profile, falling back to form data:', error);
    verifiedName = `${formData.firstName} ${formData.lastName}`;
  }

  const pdfDoc = await PDFDocument.create();
  let currentPage = pdfDoc.addPage([612, 792]);
  const { height } = currentPage.getSize();
  let currentY = height - 50;
  
  const font = await pdfDoc.embedFont(StandardFonts.TimesRoman);
  const boldFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);

  const addTextWithWrapping = (page, text, { x, y, maxWidth = 500, size = 12, font }) => {
    const sanitizedText = sanitizeText(text);
    const paragraphs = sanitizedText.split('\n').filter(p => p.trim().length > 0);
    let localY = y;
    const lineHeight = size * 1.2;
    let localPage = page;

    for (const paragraph of paragraphs) {
      // Check if we need a new page
      if (localY < 100) {
        localPage = pdfDoc.addPage([612, 792]);
        localY = height - 50;
      }

      const words = paragraph.trim().split(' ');
      let line = '';
      
      for (const word of words) {
        const testLine = line + (line ? ' ' : '') + word;
        const textWidth = font.widthOfTextAtSize(testLine, size);
        
        if (textWidth > maxWidth) {
          // Check if we need a new page before drawing current line
          if (localY < 100) {
            localPage = pdfDoc.addPage([612, 792]);
            localY = height - 50;
          }
          
          if (line) {
            localPage.drawText(line.trim(), { x, y: localY, size, font });
            localY -= lineHeight;
          }
          line = word;
        } else {
          line = testLine;
        }
      }
      
      // Check if we need a new page before drawing final line of paragraph
      if (localY < 100) {
        localPage = pdfDoc.addPage([612, 792]);
        localY = height - 50;
      }
      
      if (line) {
        localPage.drawText(line.trim(), { x, y: localY, size, font });
        localY -= lineHeight;
      }
      
      // Add space between paragraphs
      localY -= lineHeight * 0.5;
    }
    
    return { currentY: localY, currentPage: localPage };
  };

  const addSection = (text, { size = 12, isBold = false, indent = 0 } = {}) => {
    const result = addTextWithWrapping(
      currentPage,
      text,
      {
        x: 50 + indent,
        y: currentY,
        size,
        font: isBold ? boldFont : font,
        maxWidth: 500 - indent
      }
    );
    
    currentY = result.currentY;
    currentPage = result.currentPage;
    
    return currentY;
  };

  // Header
  addSection('EVENT AGREEMENT', { size: 20, isBold: true });

  // Introduction
  addSection(replaceTemplateVariables(waiverText.introduction, formData));

  // Form Fields with verified name
  const fields = [
    { label: 'Athlete Name', value: verifiedName },
    { label: 'Legal Name (as it appears on ID)', value: formData.legalName },
    { label: 'Home Address', value: formData.homeAddress },
    { label: 'Date of Birth', value: formData.dateOfBirth },
    { label: 'ID Type', value: formData.idType.split('_').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ') },
    { label: 'Identification Number', value: formData.idNumber },
    { label: 'State/Country', value: formData.idStateCountry },
    { label: 'Expiration Date', value: formData.idExpirationDate },
    { label: 'Telephone Number', value: formData.phoneNumber },
    { label: 'Email Address', value: formData.email }
  ];

  console.log('PDF fields being added:', fields);

  fields.forEach(({ label, value }) => {
    console.log(`Adding field and value to pdf: ${label}: ${value}`);
    addSection(`${label}: ${value}`);
  });

  // Add signature to first page
  if (signature?.type === 'drawn') {
    const signatureImage = await pdfDoc.embedPng(signature.data);
    currentPage.drawImage(signatureImage, {
      x: 50,
      y: currentY - 40,
      width: 200,
      height: 50,
    });
    currentY -= 60;
  } else if (signature?.type === 'typed') {
    addSection(signature.data, { size: 14 });
  }

  // Add timestamp
  const timestamp = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short'
  }).format(new Date());
  addSection(`Signed and submitted on: ${timestamp}`);

  // Start new page for terms
  currentPage = pdfDoc.addPage([612, 792]);
  currentY = height - 50;

  // Add all sections
  [
    waiverText.taxCertification,
    waiverText.codeOfConduct,
    waiverText.personalConduct,
    waiverText.medicalSafety,
    waiverText.antiGambling,
    waiverText.antiDoping
  ].forEach(section => {
    addSection(section);
  });

  const pdfBytes = await pdfDoc.save();
  const date = new Date();
  const fileName = `waiver_${formData.lastName.toLowerCase()}_${playerId.slice(0,4)}_${date.getDate()}${date.getMonth()+1}${date.getFullYear().toString().slice(-2)}.pdf`;

  return {
    doc: pdfDoc,
    bytes: pdfBytes,
    fileName: fileName
  };
};