import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchPlayers } from '../services/apiService';
import {
  TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Checkbox, Button, Grid, Snackbar, Alert,
} from '@mui/material';

const PlayerAdminPage = () => {
  const [players, setPlayers] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const navigate = useNavigate();

  useEffect(() => {
    const loadPlayers = async () => {
      try {
        const fetchedPlayers = await fetchPlayers();
        setPlayers(fetchedPlayers);
      } catch (error) {
        console.error('Error loading players:', error);
      }
    };
    loadPlayers();
  }, []);

  const handleSearchChange = (e) => setSearchQuery(e.target.value);

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedPlayers([]);
    } else {
      setSelectedPlayers(players.map((player) => player.PlayerId));
    }
    setSelectAll(!selectAll);
  };

  const handlePlayerSelection = (playerId) => {
    setSelectedPlayers((prevSelected) =>
      prevSelected.includes(playerId)
        ? prevSelected.filter((id) => id !== playerId)
        : [...prevSelected, playerId]
    );
    setSelectAll(false);
  };

  const handleNext = () => {
    if (selectedPlayers.length === 0) {
      setSnackbar({ open: true, message: 'Please select at least one player.', severity: 'error' });
      return;
    }
    navigate('/generate-waiver-link', { state: { selectedPlayers } });
  };

  const filteredPlayers = players.filter((player) =>
    [player.FirstName, player.LastName, player.Email].some((field) =>
      field?.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <div style={{ padding: '10px', maxWidth: '100%', margin: '0 auto' }}>
      <TextField
        label="Search Players"
        variant="outlined"
        value={searchQuery}
        onChange={handleSearchChange}
        fullWidth
        size="small"
        sx={{ marginBottom: 2 }}
      />

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox checked={selectAll} onChange={handleSelectAll} />
              </TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPlayers.map((player) => (
              <TableRow key={player.PlayerId}>
                <TableCell>
                  <Checkbox
                    checked={selectedPlayers.includes(player.PlayerId)}
                    onChange={() => handlePlayerSelection(player.PlayerId)}
                  />
                </TableCell>
                <TableCell>{player.FirstName}</TableCell>
                <TableCell>{player.LastName}</TableCell>
                <TableCell>{player.Email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
        <Button variant="contained" color="primary" onClick={handleNext}>
          Next
        </Button>
      </Grid>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
    </div>
  );
};

export default PlayerAdminPage;
