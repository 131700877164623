import React, { useState } from 'react';
import { Dialog, DialogContent, Tabs, Tab, Box } from '@mui/material';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';

const AuthModal = ({ open, onClose, onSuccess }) => {
    const [tab, setTab] = useState('login');

    return (
        <Dialog 
            open={open} 
            maxWidth="sm"
            fullWidth
            disableEscapeKeyDown
            hideBackdrop={false}
            onClose={() => {}}
        >
            <DialogContent>
                <Tabs 
                    value={tab} 
                    onChange={(e, v) => setTab(v)}
                    centered
                >
                    <Tab value="login" label="Sign In" />
                    <Tab value="signup" label="Sign Up" />
                </Tabs>
                <Box sx={{ mt: 2 }}>
                    {tab === 'login' ? (
                        <LoginForm onSuccess={onSuccess} isModal />
                    ) : (
                        <SignupForm onSuccess={onSuccess} isModal />
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default AuthModal;
