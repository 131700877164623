import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SignatureInput from "../components/SignatureInput";
import { getPlayerProfile, fetchTournament, fetchSeason, fetchLeague, fetchSport } from "../services/apiService";
import { generateWaiverPDF } from "../utils/waiverPdfGenerator";
import { waiverText } from "../utils/waiverText";
import { signup, uploadDrivingLicense, uploadSignedDocument, login, logout, updatePlayerWaiverInfo } from '../services/apiService';

const WaiverExistingUserPage = () => {
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    dateOfBirth: '',
    idType: 'drivers_license',
    idNumber: '',
    idStateCountry: '',
    idExpirationDate: '',
  });
  const [tournamentData, setTournamentData] = useState(null);
  const [signature, setSignature] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "info" });
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const navigate = useNavigate();
  const [sportName, setSportName] = useState('PLN Sport');

  useEffect(() => {
    const init = async () => {
      const params = new URLSearchParams(window.location.search);
      const tournamentId = params.get('tournamentId');
      const playerId = params.get('playerId');
      
      console.log('URL Parameters:', {
        tournamentId,
        playerId
      });

      try {
        if (tournamentId) {
          const tournament = await fetchTournament(tournamentId);
          console.log('Tournament data fetched:', tournament);
          setTournamentData(tournament);
          
          try {
            const season = await fetchSeason(tournament.SeasonId);
            const league = await fetchLeague(season.LeagueId);
            const sport = await fetchSport(league.SportId);
            
            if (league.LeagueName?.toLowerCase() === 't3' || 
                sport.SportName?.toLowerCase() === 'test') {
              setSportName('Str33t');
            } else {
              setSportName(sport.SportName);
            }
          } catch (error) {
            console.warn('Could not fetch sport details:', error);
            setSportName('PLN Sport');
          }
        }
      } catch (error) {
        console.error('Error fetching tournament:', error);
      }
    };

    init();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const playerId = urlParams.get("playerId");
    const accessToken = urlParams.get("access_token");

    if (accessToken) {
      console.log("Storing access token in localStorage:", accessToken);
      localStorage.setItem("token", accessToken);
    } else {
      setSnackbar({ open: true, message: "Access token is missing.", severity: "error" });
      return;
    }

    if (playerId) {
      setLoading(true);
      getPlayerProfile(playerId)
        .then((profile) => {
          setFormData({
            firstName: profile.FirstName || "",
            lastName: profile.LastName || "",
            phoneNumber: profile.PhoneNumber || "",
            dateOfBirth: profile.DOB || "",
            email: profile.Email || "",
          });
          localStorage.setItem("playerId", playerId);
        })
        .catch((error) => {
          console.error("Error fetching player profile:", error);
          setSnackbar({ open: true, message: "Failed to load player data.", severity: "error" });
        })
        .finally(() => setLoading(false));
    } else {
      setSnackbar({ open: true, message: "Invalid or missing player ID.", severity: "error" });
    }
  }, []);

  const handleSubmit = async () => {
    if (!signature) {
      setSnackbar({ open: true, message: "Please provide a signature.", severity: "error" });
      return;
    }

    try {
      setLoading(true);
      const playerId = localStorage.getItem("playerId");
      const profile = await getPlayerProfile(playerId);
      
      // Format tournament data
      const formattedStartDate = tournamentData?.StartDate ? 
        new Date(tournamentData.StartDate).toLocaleDateString('en-US', {
          year: 'numeric', month: 'long', day: 'numeric'
        }) : 'TBD';

      const { doc, bytes, fileName } = await generateWaiverPDF(
        {
          // Player Profile Data
          firstName: profile.FirstName,
          lastName: profile.LastName,
          email: profile.Email,
          phoneNumber: profile.PhoneNumber,
          dateOfBirth: profile.DOB,
          legalName: profile.LegalName,
          homeAddress: profile.HomeAddress,
          idType: profile.IDType,
          idNumber: profile.IDNumber,
          idStateCountry: profile.IDCountry,
          idExpirationDate: profile.IDExpirationDate,

          // Tournament Data
          tournamentName: tournamentData?.TournamentName || 'PLN Event',
          startDate: formattedStartDate,
          prizes: tournamentData?.Prizes || '0',
          venue: '1157 Lemoyne St',
          sport: sportName
        },
        signature
      );

      const pdfBlob = new Blob([bytes], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const pdfFile = new File([bytes], fileName, { type: "application/pdf" });

      setPdfPreviewUrl(pdfUrl);
      setPdfFile(pdfFile);
      setPreviewDialogOpen(true);
    } catch (error) {
      console.error("Error generating PDF:", error);
      setSnackbar({ open: true, message: "Failed to generate waiver PDF.", severity: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = async () => {
    const playerId = localStorage.getItem("playerId");
  
    if (!pdfFile) {
      setSnackbar({ open: true, message: "PDF file is missing.", severity: "error" });
      return;
    }
  
    try {
      setLoading(true);
      console.log("Uploading signed PDF...");
  
      const result = await uploadSignedDocument(playerId, pdfFile); // Call the function from apiService.js
      console.log("Upload success response:", result);
  
      setSnackbar({ open: true, message: "Waiver submitted successfully!", severity: "success" });
      navigate("/thank-you");
    } catch (error) {
      console.error("Error uploading signed document:", error);
      setSnackbar({ open: true, message: `Failed to upload waiver. Error: ${error.message}`, severity: "error" });
    } finally {
      setLoading(false);
    }
  };
  
  const handleClosePreviewDialog = () => {
    setPreviewDialogOpen(false);
  };

  const replaceTemplateVariables = (text, tournament) => {
    console.log('Replacing variables with tournament data:', tournament);
    
    // Format start date nicely if it exists
    const formattedStartDate = tournament.StartDate ? 
      new Date(tournament.StartDate).toLocaleDateString('en-US', {
        year: 'numeric', month: 'long', day: 'numeric'
      }) : 'TBD';

    return text
      .replace(/{{tournamentName}}/g, tournament.TournamentName || 'PLN Event')
      .replace(/{{prizes}}/g, `${tournament.Prizes || '0'}`)
      .replace(/{{startDate}}/g, formattedStartDate)
      .replace(/{{venue}}/g, '1157 Lemoyne St')
      .replace(/{{sport}}/g, sportName);
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h4" sx={{ mt: 4, mb: 2 }}>
          {sportName ? `${sportName} Waiver Form` : 'Tournament Waiver Form'}
        </Typography>
        
        {formData.firstName && (
          <Typography variant="body1" sx={{ mb: 2 }}>
            Welcome {formData.firstName} {formData.lastName}
          </Typography>
        )}
        <Typography variant="subtitle1" color="text.secondary">
          {sportName} Tournament: {tournamentData?.TournamentName}
        </Typography>
      </Box>

      <Paper sx={{ p: 4, mb: 2 }}>
        <Typography variant="body1" paragraph>
          {tournamentData ? replaceTemplateVariables(waiverText.introduction, tournamentData) : waiverText.introduction}
        </Typography>
        <Typography variant="body1" paragraph>{waiverText.codeOfConduct}</Typography>
        <Typography variant="body1" paragraph>{waiverText.personalConduct}</Typography>
        <Typography variant="body1" paragraph>{waiverText.antiGambling}</Typography>
        <Typography variant="body1" paragraph>{waiverText.antiDoping}</Typography>
      </Paper>

      <Paper sx={{ p: 2, mb: 4, bgcolor: 'grey.50' }}>
        <Typography variant="h6" sx={{ mb: 2 }}>Confirm Your Information</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Name: {formData.firstName} {formData.lastName}</Typography>
            <Typography sx={{ wordBreak: 'break-all' }}>Email: {formData.email}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Phone: {formData.phoneNumber}</Typography>
            <Typography>Date of Birth: {formData.dateOfBirth}</Typography>
          </Grid>
        </Grid>
      </Paper>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Signature
        </Typography>
        <SignatureInput onSignatureChange={setSignature} />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Generate & Preview Waiver
        </Button>
      </Box>

      <Snackbar
        open={snackbar.open}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Dialog open={previewDialogOpen} onClose={handleClosePreviewDialog}>
        <DialogTitle>Waiver Preview</DialogTitle>
        <DialogContent>
          <iframe src={pdfPreviewUrl} width="100%" height="500px" title="PDF Preview"></iframe>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePreviewDialog}>Cancel</Button>
          <Button onClick={handleUpload} color="primary">
            Submit Waiver
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default WaiverExistingUserPage;
