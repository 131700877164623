import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { fetchTournaments, sendWaiverLinkEmail, getPlayerProfile  } from "../services/apiService";



const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://plnapi-dev.azurewebsites.net";

const GenerateWaiverLinksPage = () => {
  const location = useLocation();
  const selectedPlayers = location.state?.selectedPlayers || [];
  const [tournamentId, setTournamentId] = useState("");
  const [tournaments, setTournaments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "info" });
  const [emailStatuses, setEmailStatuses] = useState([]); 

  // Fetch tournaments on component mount
  useEffect(() => {
    const fetchTournamentsList = async () => {
      try {
        setLoading(true);
        const tournamentsList = await fetchTournaments();
        setTournaments(tournamentsList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching tournaments:", error);
        setSnackbar({
          open: true,
          message: "Failed to fetch tournaments.",
          severity: "error",
        });
        setLoading(false);
      }
    };

    fetchTournamentsList();
  }, []);

  const handleSubmit = async () => {
    if (!tournamentId || selectedPlayers.length === 0) {
      setSnackbar({
        open: true,
        message: "Please select a tournament and at least one player.",
        severity: "error",
      });
      return;
    }

    setLoading(true);
    const statuses = [];
    try {
      for (const playerId of selectedPlayers  ) {
        try {
          // Fetch player details using `getPlayerProfile`
          const player = await getPlayerProfile(playerId);
          if (!player || !player.Email) {
            statuses.push({
              playerId,
              message: `Failed to fetch email for player ID ${playerId}`,
              status: "error",
            });
            continue;
          }

          // Generate waiver link
          const waiverResponse = await fetch(`${API_BASE_URL}/api/auth/generate_waiver_link`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              PlayerId: playerId,
              TournamentId: tournamentId,
            }),
          });

          const waiverData = await waiverResponse.json();

          if (!waiverResponse.ok) {
            statuses.push({
              playerId,
              message: waiverData.message || "Failed to generate waiver link",
              status: "error",
            });
            continue;
          }

          const waiverLink = waiverData.form_link;

          // Send email with the generated waiver link
          await sendWaiverLinkEmail(player.Email, waiverLink);

          statuses.push({
            playerId,
            message: `Email sent successfully to ${player.FirstName} ${player.LastName} (${player.Email})`,
            status: "success",
          });
        } catch (error) {
          statuses.push({
            playerId,
            message: `Error processing player ID ${playerId}: ${error.message}`,
            status: "error",
          });
        }
      }

      setEmailStatuses(statuses);

      // Show overall success or warning message
      if (statuses.some((status) => status.status === "error")) {
        setSnackbar({
          open: true,
          message: "Some emails failed to send. Check the statuses below.",
          severity: "warning",
        });
      } else {
        setSnackbar({
          open: true,
          message: "All emails sent successfully!",
          severity: "success",
        });
      }
    } catch (error) {
      console.error("Error generating waiver links or sending emails:", error);
      setSnackbar({
        open: true,
        message: "Failed to generate waiver links or send emails.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" sx={{ mt: 4, mb: 2 }}>
        Generate Waiver Links for Tournament
      </Typography>

      <Paper sx={{ p: 4, mb: 2 }}>
        <FormControl fullWidth sx={{ mb: 3 }}>
          <InputLabel>Tournament</InputLabel>
          <Select
            value={tournamentId}
            onChange={(e) => setTournamentId(e.target.value)}
            label="Tournament"
          >
            {tournaments.map((tournament) => (
              <MenuItem key={tournament.TournamentId} value={tournament.TournamentId}>
                {tournament.TournamentName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Paper>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Generate & Send Waiver Links
        </Button>
      </Box>

      <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
        Email Sending Status
      </Typography>
      <Paper sx={{ p: 2 }}>
        <List>
          {emailStatuses.map((status, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={status.message}
                secondary={status.status === "success" ? "Success" : "Error"}
              />
            </ListItem>
          ))}
        </List>
      </Paper>

      <Snackbar
        open={snackbar.open}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
        autoHideDuration={6000}
      >
        <Alert onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default GenerateWaiverLinksPage;